import Step from '@/components/global/Step/Step.vue'
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import ProductItem from './components/ProductItem.vue'
import groupProductItem from "./components/groupProductItem"
import {
  saveMany,
  settlementZone,
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  // getWeek,
  // 商品价格列表
  goodsList,
  // 获取商品列表库存数
  getNumber,
  // 查看预占用额度明细
  quotaDeatail,
  // 特惠专区接口
  specialZone,
  // 渠道结算接口
  // 获取分销商地址 非 dms
  getAddress,
  // 调用五遍接口
  getSupplierS,
  // dms配送方式
  getDeliveryMode,
  // 查询商品行信息
  getCustomer,
  // 订单提交
  orderSubmit,
  // 下单完成 删除商品
  deletGoods,
  // 分销价格
  fenxiaoPrice,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory,
  changeTransFlag,
  submitCis,
  sysconfig
} from './api';
import
moment
  from 'moment';
import { getRetailPrice } from "@/views/stockCenter/adjustmentApply/components/api";
import { ctsApi, fxZsyhGetDeliveryMethod, isBrightPilotRegion, orderSubmit2 } from "@/views/channel/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { getAdressapi } from "@/views/settlement/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    groupProductItem,
    addUserSource,
    addUserTips
  },
  data() {
    return {
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      prods: [],
      //form
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: '',
      // 联系人
      contacts: '',
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: '',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '',
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',
      companyList:[],
      addressHx:[],
      fetching:false,
      // 省市区三级联动
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '入库仓库',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {
        proviceCode: '',
        cityCode: '',
        townCode: '',
        zCode: '',
      },
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },
      // 要求到货周
      arriveOptions: [],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      // 上个页面传过来的参数
      RouterParams: {
        flag: 1,
        counts: '1,2,1',
        ids: '2,3,2',
        mainId: 2
      },
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      orgCode: '',
      matklId: '',
      orgId: '',
      orgtitle: '',
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: '',
      // 有效截止时间
      startTime: '',
      // 后台接口最大截止时间
      maxEndDate: '',
      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/activity",
        name: "activity",
        title: "活动专区"
      },
      {
        path: "",
        name: "channle",
        title: "活动专区结算"
      }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      //备注
      BZvalue: '',
      // 收货地址
      shippingAddress: [],
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],
      // 采购人员id
      userid: '',
      supplierCode: '',
      provinceCode: '',
      cityCode: '',
      districtCode: '',
      URLTO: '',
      saveMoneyValue: {},
      dataArr: [],
      activeType: '',
      custId: '',
      configCode:'',
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      TipsNames: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      shopSelectId:'',
      isChooseNum:0,
      isChooseNumStart:0,
      activityName:'',
      activityNameShow:'',
      quantityControl:'',
      parentCisCode:'',
      isShowLogisticsIcon:false,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',// 配送方式回显 index
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      addressDftVal:[], // 收货地址回填的值
      isBright:false,// 判断是试点区域
      brightNum:0,// 璀璨数量
      brightPrice:0,//璀璨价格
      olnyNum:0,// 专供数量
      olnyPrice:0,//专供价格
    };
  },
  watch:{
    GoodsList: {
      handler:function(val,old) {
        if(this.GoodsList.length > 0) {
          this.GoodsList.forEach(el => {
            // let retailData = {
            //   cisCode:this.$store.state.user.userInfo.customer.cisCode,
            //   productId: el.productInfoId,
            //   refreshPrice: true,
            //   type: 3,
            // }
            // getRetailPrice(retailData).then(res=>{
            //   el.salePrice = this.$util.getFloat(res.data.price,2)
            //   el.retailPrice = this.$util.getFloat(res.data.price,2)
            //   this.$forceUpdate()
            // })
            checkRetailL({id:el.productInfoId}).then(res => {
              if(res.data && res.data.code == 0){
                const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
                el.min = min;
                el.bei = bei;
                el.type = type;
                el.max = max;
                el.salePrice = this.$util.getFloat(Number(money),2)
                el.retailPrice = this.$util.getFloat(Number(money),2)
                el.haveRetailL = haveRetailL;
              }
            })
          })
        }


      },
      // 深度观察监听
      deep: true
    },
    isChooseNum:{
      handler:function(val,old) {
        if(this.GoodsList.length>0&&this.GoodsList[0].discountTypeName == '套购') {
          this.goodsNum = 0
          this.goodsVolume = 0
          this.goodsPrice = 0
          this.olnyNum = 0
          this.olnyPrice= 0
          this.brightNum= 0
          this.brightPrice= 0
          this.GoodsList.forEach(item=>{
            this.goodsNum += (item.packageNum* this.isChooseNum)
            this.goodsVolume += (item.packageNum* this.isChooseNum * item.volume)
            this.goodsPrice += (item.packageNum* this.isChooseNum * item.billPrice)
          })
            // 获取专供
            const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
           if(zg.length>0) {
            zg.forEach(el=>{
              this.olnyNum+=(el.packageNum * this.isChooseNum)
              this.olnyPrice+=Number(this.isChooseNum) * Number(el.billPrice)*Number(el.packageNum);
            })
           }
            // 获取璀璨
            const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
            if(cc.length>0) {
              cc.forEach(el=>{
                this.brightNum+=(el.packageNum * this.isChooseNum)
                this.brightPrice+=Number(this.isChooseNum) * Number(el.billPrice)*Number(el.packageNum);
              })
            }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    // 查询入库仓库getSupplierS
    this.inWarehouse()
    // 结算单位
    this.cglrjsdw()
    // dms 收货地址
    // this.cglrshdz()
    // 业务员
    this.ywy()
    // 采购录入业务员
    this.cglrywy();

    // 查询商品行信息
    // 购物车查询
    if (this.$route.query.custId) {
      this.getadressInfo()
      this.custId = this.$route.query.custId
    } else {
      this.getMessage();
    }
    this.getConfigCode()
    this.getuserSorceList()
    this.getuserTipList()
    this.getShopList()
    // this.isChooseNum =  this.$route.query.isChooseNum?this.$route.query.isChooseNum:0;
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark
  },
  methods: {
    changePhoneNum() {
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='04'||this.trans=='08')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )
            if(this.userTipsList.length>0){
              this.userTipsList.forEach(item=>{
                item.checked = false
                this.TipsNames = []
                this.TipsIds = []
              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);

                  }
                })
              })
            }else {
              this.sexId = 1
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
            }
          } else {
            this.sexId = 1
            this.addressHx = []
            this.showSorce = false
            this.TipsIds = []
            this.TipsNames = []
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },
    // 获取配送方式以及判断直送用户是否可选
    getFxGetDeliveryMethod() {
      const items = [], orgMatkl = []
      if(this.GoodsList && this.GoodsList.length>0) {
        this.GoodsList.forEach(el => {
          if(orgMatkl.length == 0 || !orgMatkl.includes(`${el.fwOrgId}-${el.matklCode}`)) {
            orgMatkl.push(`${el.fwOrgId}-${el.matklCode}`)
          }
        })
        orgMatkl.forEach((it, index) => {
          items.push({
            orgId: it.split('-')[0],
            matklCode: it.split('-')[1],
            productIds: []
          })
          this.GoodsList.forEach(el => {
            if(it == `${el.fwOrgId}-${el.matklCode}`) {
              items[index].productIds.push({
                id: el.productInfoId
              })
            }
          })
        })
      }

      let data = {
        orderType:2,
        // serviceCode: 'cisFxZsyhGetDeliveryMethod',
        code:this.$store.state.user.userInfo.customer.cisCode,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode: this.parentCisCode,
        items: items,
      }
      fxZsyhGetDeliveryMethod(data).then(res=>{
        if(res.data.code == 0) {
          this.options = res.data.data.items
          this.isShowLogisticsIcon = this.options.some(el => ['04', '08'].includes(el.code))
          // 获取接口中check == true 默认选中的下标
          this.transDefault = this.options.findIndex(el => el.check == true)>-1?this.options.findIndex(el => el.check == true):''
          // 赋值配送方式
          this.trans = this.options[this.transDefault].code
          // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
          this.haveDeliveryFee = this.options[this.transDefault].haveDeliveryFee == 1? true : false
          this.$forceUpdate()
        }
      })

    },

    // 查询服务费
    getFWF(){
      if( !this.proObj.zCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      this.startTime = ''
      this.startShowTime = ''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.productInfoId,		//产品id
          "warehouseInfoId": "",			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": this.activeType=='套购'?item.buyQtyCherk*this.isChooseNum:item.buyQtyCherk,
          zzprdmodel:item.productName,
          orgId:item.fwOrgId
        })
      })
      let data = {
        "townCode":  this.proObj.zCode,
        "countyCode": this.proObj.townCode,
        "cityCode":  this.proObj.cityCode,
        "provinceCode":  this.proObj.proviceCode ,
        "warehouseInfoId": '',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopId":  '',			//门店idthis.shopSelectId
        "documentSource":"5",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      findMoneyBuyBegin(data).then(res => {
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.startTime =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.startTime =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              if( itemFirst.productId == item.productInfoId ){
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
        }
        if(res.data.code == 1 ){
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney','0.00')
            })
          })
        }
      })
    },
    onAddressChange(addressValArr) {
      let oldAdress = this.districtCode
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.proObj.townCode == ''
        this.districtCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.proObj.zCode = ''
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.proObj.proviceCode = item
          }
          if (index == 1) {
            this.proObj.cityCode = item
          }
          if (index == 2) {
            this.proObj.townCode = item
          }
          if (index == 3) {
            this.proObj.zCode= item;
            if((this.trans=='04' || this.trans == '08') && this.proObj.zCode){
              this.getFWF()
            }
          }

        });
      } else {
        this.proObj.proviceCode = ''
        this.proObj.cityCode = ''
        this.proObj.townCode = ''
        this.proObj.zCode = ''
        this.startShowTime =''
        this.startTime =''
      }
      // 如果之前的三级地址修改了  就清空详细地址
      if(oldAdress !=  this.districtCode ){
        this.userDetailAddress = ''
      }
      this.companyList = []
    },

    changeSEX(e) {
      this.sexId = e.target.value;
    },
    changePrice(item){
      this.$forceUpdate()
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item,name) {
      this.TipsIds = [];
      this.TipsNames= [];
      this.TipsIds = item;
      this.TipsNames = name
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {

      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },

    // // 销量所属门店
    getShopList(){
      // cisFxZsyhGetxlShop
      let data = {
        serviceCode: 'cisFxZsyhGetXlShop',
        code:this.$store.state.user.userInfo.customer.cisCode
      }
      ctsApi(data).then(res=>{
        if(res.data.code == 0) {
          res.data.data.forEach(el => {
            el.code = el.cisCode
            el.name = el.fullName
            el.selected = false
          })
          this.saleShopList = res.data.data
          if(this.saleShopList.length>0){
            this.receiveShop = this.saleShopList[0].name;
            this.salesShopInfoId = this.saleShopList[0].code;
            let obj = this.saleShopList.find((i)=>{
              return i.code ===  this.saleShopList[0].code;//筛选出匹配数据
            });
            this.shopSelectId = obj.id
          }
        }
      })
    },
    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      this.startShowTime =''
      this.startTime =''
      this.$forceUpdate()
      let obj = this.saleShopList.find((i)=>{
        return i.code ===  e.key;//筛选出匹配数据
      });
      this.shopSelectId = obj.id
      this.startTime = ''
      this.startShowTime = ''
      if((this.trans=='04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
    },
    blurAddress(){
      if(! this.proObj.zCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(! this.proObj.zCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    dataChange(event) {
      if((this.trans=='04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()

      }
      this.goodsNum = 0
      this.goodsVolume = 0
      this.goodsPrice = 0
      this.olnyNum = 0
      this.olnyPrice= 0
      this.brightNum= 0
      this.brightPrice= 0
      for (const item of this.GoodsList) {
        this.goodsNum += Number(item.buyQtyCherk);
        this.goodsVolume += Number(item.buyQtyCherk) * Number(item.volume);
        this.goodsPrice += item.buyQtyCherk * item.billPrice;
      }
      // 获取专供
      const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
      if(zg.length>0) {
        zg.forEach(el=>{
          this.olnyNum+=Number(el.buyQtyCherk) 
          this.olnyPrice+=Number(el.buyQtyCherk) * Number(el.billPrice);
        })
      }
     
      // 获取璀璨
      const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
      if(cc.length>0) {
        cc.forEach(el=>{
          this.brightNum+=Number(el.buyQtyCherk) 
          this.brightPrice+=Number(el.buyQtyCherk) * Number(el.standardPrice);
        })
      }
      // this.$forceUpdate();
    },
    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //是否返利点击
    tradeOnChange(e) {
      // e.target.checked = !e.target.checked;
      this.isTradeChecked = e.target.checked;
    },
    // 收货地址 非dms
      findAddress(matklId, orgId) {
      let data = {
        orgId: orgId,
        matklId: matklId,
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
      }
      getAddress(data).then(res => {
        this.shippingAddress = res.data.list;
        if (res.data.list.length > 0) {
          this.officeCode = res.data.list[0].id;
          this.receivehis = res.data.list[0].address;
          this.distributionAdd.DISCODE = res.data.list[0].id;
        }
        this.parentCisCode = res.data.parentCisCode || ''
        this.getFxGetDeliveryMethod()
        this.showAnotheer()
      })
    },
    // 入库仓库
    inWarehouse() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "cglrrkck"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              key,
              value: item[key]
            })
          }
        }
        if (obj.data.length > 0) {
          this.receive = obj.data[0][Object.keys(obj.data[0])[0]];
          this.receiveID = Object.keys(obj.data[0])[0];
        }
        this.orderDis = arr;
      })
    },
    // 结算单位
    cglrjsdw() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrjsdw"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)

        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              key,
              value: item[key]
            })
          }
        }
        this.invoice = arr[0].value;
        this.invoiceId = arr[0].key;
        this.billToDTOs = arr;
      })
    },
    // dms配送方式
    getPS() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      }
      getDeliveryMode(data).then(res => {
        var obj = JSON.parse(res.data)

        // // 配送方式
        // this.options = res.data.cartOrder.deliveryList;
        // // 默认配送方式id
        // this.trans = res.data.cartOrder.deliveryList[0].id
        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              id: key,
              propertyName: item[key]
            })
          }
        }
        this.options = arr;
        this.trans = arr[0].id;

      })
    },
    // 业务员
    ywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "ywy"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
      })
    },
    // 采购录入业务员
    cglrywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrywy"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
        this.userid = Object.keys(obj.data[0])[0];
      })
    },
    // 如果是代理商下发给分销商的数据
    getadressInfo() {
      let data = {
        prdIds: this.$route.query.prdIds,
        buyNums: this.$route.query.buyNums,
        orgId: this.$route.query.orgId

      }
      this.pageLoadFlag = true
      settlementZone(data).then(res => {
        this.GoodsList = res.data.activityList;
        this.activityName = res.data && res.data.activityList && res.data.activityList.length>0? res.data.activityList[0].discountTypeName : ''
        this.quantityControl =  res.data && res.data.activityList && res.data.activityList.length>0? res.data.activityList[0].quantityControl: ''
        this.activityNameShow = res.data &&res.data.activityList && res.data.activityList.length>0? res.data.activityList[0].activityName : ''

        if(this.activityName=='套购'){
          this.isChooseNum = res.data.activityList&& res.data.activityList.length>0? this.GoodsList[0].buyQty/this.GoodsList[0].packageNum:0
          this.isChooseNumStart = res.data.activityList&& res.data.activityList.length>0? this.GoodsList[0].buyQty/this.GoodsList[0].packageNum:0

          }
        if (res.data.activityList.length > 0) {
          res.data.activityList.forEach(item => {
            if(this.activeType !== '套购'){
              item.buyQtyCherk = item.buyQty
            } else {
              item.buyQtyCherk = item.packageNum
            }
          })
        }
        this.suppilers = res.data.agentName
        this.orgId = res.data.orgId
        this.orgName = res.data.orgName
        this.matklId = res.data.matklId
        for (const item of this.GoodsList) {
          this.goodsNum += Number(item.buyQty);
          this.goodsVolume += Number(item.buyQty) * Number(item.volume);

          this.goodsPrice += item.buyQty * item.billPrice;
          // //返利金额
          // rebate += item.buyQty * item.standardPrice * this.TotalPayment.rebate
        };
           // 获取专供
      const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
      if(zg.length>0) {
        zg.forEach(el=>{
          this.olnyNum+=Number(el.buyQty) 
          this.olnyPrice+=Number(el.buyQty) * Number(el.billPrice);
        })
      }
      // 获取璀璨
      const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
      if(cc.length>0) {
        cc.forEach(el=>{
          this.brightNum+=Number(el.buyQty) 
          this.brightPrice+=Number(el.buyQty) * Number(el.standardPrice);
        })
      }
        this.activeType = res.data.activityList[0].discountTypeName
        this.findAddress(this.matklId,this.orgId)

        // // 售达地址
        // this.shippingAddress = res.data.customerAddressDTOs;
        // if(res.data.customerAddressDTOs.length > 0) {
        //   this.receivehis = res.data.customerAddressDTOs.length > 0 ? res.data.customerAddressDTOs[0].address :' ';
        //   this.officeCode = res.data.customerAddressDTOs.length > 0 ? res.data.customerAddressDTOs[0].id:''
        //   // this.officeCode = res.data.customerAddressDTOs[0].id;
        //   this.getPeople(res.data.customerAddressDTOs[0].id)
        // }



        this.getRebateData()
        this.pageLoadFlag = false
      })
    },
    // 查询商品行信息和收货地址
    getMessage() {
      let params = {
        userActId: this.$route.query.id
      }
      this.pageLoadFlag = true
      getCustomer(params).then(res => {
        this.GoodsList = res.data.activityList;
        this.activityName = res.data && res.data.activityList.length>0? res.data.activityList[0].discountTypeName : ''
        this.quantityControl =  res.data && res.data.activityList.length>0? res.data.activityList[0].quantityControl: ''
        this.activityNameShow = res.data && res.data.activityList.length>0? res.data.activityList[0].activityName : ''
        if(this.activityName=='套购'){
          this.isChooseNum = res.data.activityList&& res.data.activityList.length>0?  res.data.activityList[0].buyQty/ res.data.activityList[0].packageNum:0
          this.isChooseNumStart = res.data.activityList&& res.data.activityList.length>0? this.GoodsList[0].buyQty/this.GoodsList[0].packageNum:0
        }
        this.activeType = res.data.activityList[0].discountTypeName
        this.getFxGetDeliveryMethod()
        if (res.data.activityList.length > 0) {
          res.data.activityList.forEach(item => {
            if(this.activeType !== '套购'){
              item.buyQtyCherk = item.buyQty
            } else {
              item.buyQtyCherk = item.packageNum
            }

          })
        }
        this.suppilers = res.data.agentName
        this.orgId = res.data.orgId
        this.matklId = res.data.matklId;
        this.supplierCode = res.data.agentCode
        for (const item of this.GoodsList) {
          this.goodsNum += Number(item.buyQty);
          this.goodsVolume += Number(item.buyQty) * Number(item.volume);

          this.goodsPrice += item.buyQty * item.billPrice;
          // //返利金额
          // rebate += item.buyQty * item.standardPrice * this.TotalPayment.rebate
        };
           // 获取专供
      const zg = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
      if(zg.length>0) {
        zg.forEach(el=>{
          this.olnyNum+=Number(el.buyQty) 
          this.olnyPrice+=Number(el.buyQty) * Number(el.billPrice);
        })
      }
      // 获取璀璨
      const cc = this.GoodsList.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
      if(cc.length>0) {
        cc.forEach(el=>{
          this.brightNum+=Number(el.buyQty) 
          this.brightPrice+=Number(el.buyQty) * Number(el.standardPrice);
        })
      }

        // 售达地址;
        // this.shippingAddress = res.data.customerAddressDTOs;

        this.findAddress(  this.matklId , this.orgId,)
        this.getRebateData()
        if(this.shippingAddress.length > 0) {
          // 筛选出来当前选择的数据
          let obj = this.shippingAddress.find((i)=>{//listData就是上面的数据源
            return i.id === res.data.customerAddressDTOs[0].id;//筛选出匹配数据
          });
          // 如果选择的地址状态为D 说明地址失效 弹出提示
          if(obj.regionStatus == 'D') {
            this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
            this.receivehis = ''
            this.distributionAdd.DISCODE = ''
            this.officeCode = ''
          }
        }


        this.pageLoadFlag = false
      })
    },
    getRebateData(){
      this.GoodsList.forEach((item,index) => {
        try {
          ctsApi({
            serviceCode: "displayReturnPolicy",
            bigContractNo: "",
            companyCode: item.fwOrgCode,
            contractNo: "",
            distributorsCisCode: this.$store.state.user.userInfo.account.account,
            dlMdmCode: this.suppilers.split("-")[0],
            model: item.modelSap || item.productName,
            pricingGroup: "",
            tableRow: "",
            isActivityArea: true
          }).then(res=>{
            if(res && res.data && res.data.data && res.data.data.model){
              item.isShowRebate = true;
              item.returnAmount = res.data.data.returnAmount;
              item.upperLimit = res.data.data.upperLimit;
              this.$set(this.GoodsList[index], index, item)
            }
            if(index == this.GoodsList.length - 1 && this.GoodsList[0].discountTypeName == '组合购'){
              this.changeData()
            }
          })
        } catch (error) {
          if(index == this.GoodsList.length - 1 && this.GoodsList[0].discountTypeName == '组合购'){
            this.changeData()
          }
        }
      })
    },
    changeData() {
      var map = {},
        dest = [];
      for (var i = 0; i < this.GoodsList.length; i++) {
        var ai = this.GoodsList[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      this.dataArr = dest;

    },
    // 购物车查询
    carList() {
      let params = {
        carts: this.RouterParams.carts,
        purchaseType: this.RouterParams.purchaseType,
        orgAndGroup: this.RouterParams.orgAndGroup,
        versions: this.RouterParams.versions
      }

      specialZone(params).then(res => {
        this.pageLoadFlag = false;
        // 组织编码 物料组编码
        this.matklId = res.data.cartOrder.matklId;
        this.orgId = res.data.cartOrder.orgId;
        this.obj = res.data;
        // // 配送方式
        // this.options = res.data.cartOrder.deliveryList;
        // // 默认配送方式id
        // this.trans = res.data.cartOrder.deliveryList[0].id
        // 省 list
        // this.provices = res.data.cartOrder.provices;
        // this.proObj.proviceCode = res.data.cartOrder.defaultProvice;
        // this.provinceCode = res.data.cartOrder.defaultProvice;
        // this.cityCode = res.data.cartOrder.defaultCity;
        // this.districtCode = res.data.cartOrder.defaultDistrict;
        // 获取市区
        // this.choseCity(res.data.cartOrder.defaultProvice);
      }).catch(() => {
        // console.log(err);
      })
    },
    // 子组件选择商品
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;

      })
    },
    // 省 选择
    // handleProvinceChange(value, e) {
    //
    //   this.proObj.proviceCode = this.provinceCode;
    //   this.choseCity(this.provinceCode)
    // },
    // // 获取城市
    // choseCity(code) {
    //   let data = {
    //     proviceCode: code
    //   }
    //   cityChange(data).then(res => {
    //
    //     this.getCity = res.data;
    //     //获取城镇区
    //     this.getTowns(this.cityCode)
    //     this.proObj.cityCode = this.cityCode
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // },
    // // 获取城镇区
    // getTowns(code) {
    //
    //   let data = {
    //     cityCode: code
    //   }
    //   townChange(data).then(res => {
    //
    //     this.getTown = res.data;
    //     this.proObj.townCode = this.districtCode
    //     this.getqu(this.districtCode);
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // },
    // // 获取乡镇
    // getqu(code) {
    //   let data = {
    //     districtCode: code
    //   }
    //   getQu(data).then(res => {
    //
    //     this.qu = res.data;
    //     this.proObj.zCode = res.data[0].townCode;
    //   })
    //     .catch(err => {
    //       console.log(err);
    //     })
    // },
    // // 城市选择
    // handleCityChange(e) {
    //
    //   this.proObj.cityCode = this.cityCode;
    // },
    // // 城镇选择
    // handleTownChange(e) {
    //
    //   this.proObj.townCode = this.districtCode;
    //   this.getqu(this.districtCode);
    //
    // },
    // //乡镇
    // handleZhenChange(e) {
    //
    //   this.proObj.zCode = e;
    //
    // },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },
    moreBill() {

    },
    onGiveType(e) {
      this.trans = this.options[e.target.value].code;
      // 是否展示服务费 根据配送方式中返回字段判断 // 0否，1是
      this.haveDeliveryFee = this.options[e.target.value].haveDeliveryFee == 1? true : false
      this.startTime = ''
      this.startShowTime = ''
      this.$forceUpdate()
      if(this.trans !='04' && this.trans != '08'){
        this.proObj.proviceCode = ''
        this.proObj.cityCode = ''
        this.proObj.townCode = ''
        this.proObj.zCode = ''
      }
      if(this.trans =='04' || this.trans == '08'){
        this.changePhoneNum()
      }
      if (this.houseAddress != '统仓地址') {
        if (e.target.value == 3) {
          this.distributors = false;
          this.userAdderss = false;
        } else if (this.trans == 502005) {
          if (!this.DisList || this.DisList.length == 0) {
            this.$warning({
              content: '暂无可选分销商，请选择其他配送方式',
            });
            // this.$message.info("暂无可选分销商，请选择其他配送方式");
            return;
          }
          this.distributors = true;
          this.userAdderss = false
          // 分销商 分销地址
          this.showAnotheer()
        } else {
          this.distributors = false;
          this.userAdderss = false;
        }
      }
    },
    // 分销商 分销地址切换获取手机号
    showAnotheer() {
      getPhone({
        sendToId: this.distributionAdd.DISCODE
      }).then(res => {

        this.contacts = res.data.contact;
        this.phoneCon = res.data.phone;
        this.changePhoneNum()
      })
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
      this.accountShow = !this.accountShow;
    },
    // 开票户头相关函数
    handleChange(value, e) {

      this.invoice = value;
      this.invoiceId = e.data.key;
      this.accountShow = !this.accountShow;
    },
    handleBlur() { },
    handleFocus() { },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 是否是璀璨试点区域
    isBrightMethods(){
      return isBrightPilotRegion({regionTownCode:this.proObj.zCode})
    },
   async orderSumbit() {
      if(!this.trans){
        this.$message.warning('请选择配送方式')
        return false
      }
      if(['04', '08'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['04'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['08'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          return false
        }
        if(!this.proObj.zCode) {
          this.$message.warning('请选择收货地区')
          return false
        }
        if(!this.userDetailAddress ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId) {
          this.$message.warning('请选择销量所属门店')
          return false
        }
        if(this.TipsIds.length == 0) {
          this.$message.warning('请选择用户标签')
          return false
        }
        if(this.SorceIds == '') {
          this.$message.warning('请选择用户来源')
          return false
        }
        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          this.isLoading = false
          return;
        }
        // 获取产品是璀璨的长度
        let brightRow = this.GoodsList.filter(it=>it.productLabel&&it.productLabel.indexOf('15691143849')>-1)
        // 产品有璀璨 并且 有省市区县 并且是直送用户或者送装一体
        if (this.proObj.zCode&&brightRow.length>0) {
          const response = await this.isBrightMethods()
          this.isBright = response.data.code == 0 ?response.data.data:false
        }
      }else {
        this.isBright = false
      }

      if( this.trans== '04' || this.trans== '08') {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for(let i = 0; i < arr.length; i++){
          if (!arr[i].salePrice) {
            this.$message.warning(`请输入第${i+1}件商品的零售价格`)
            this.isLoading = false
            return false
          }
          // if ( Number(arr[i].retailPrice) && (( Number(arr[i].salePrice) <  Number(arr[i].retailPrice * 0.5) ) || (  Number(arr[i].salePrice) >  Number(arr[i].retailPrice * 1) ))){
          //   this.$message.warning(`建议零售价为${arr[i].retailPrice}元，可填范围在0.5倍到1倍之间。`)
          //   this.isLoading = false
          //   return false
          // }
          if (arr[i].type == 1 && arr[i].salePrice) {
            if (Number(arr[i].salePrice) < arr[i].min || Number(arr[i].salePrice) > arr[i].max) {
              if (arr[i].haveRetailL) {
                this.$message.warning(`请在默认建议零售价的${arr[i].bei}倍范围内录入价格`);
              } else {
                this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
              }
              return;
            }
          }
        }
      }
      // 如果是套购 传值要修改
      let num = []
      if(this.isChooseNum) {
        num = this.GoodsList.map(el=>{
          return el.packageNum*this.isChooseNum
        })
      }
      // 如果从活动列表进入当前页面 调用savemany(cis)接口 否则直接调用结算接口
      if (this.$route.query.custId) {
        let data = {
          prdIds: this.$route.query.prdIds,
          buyNums:this.isChooseNum?num.join(','): this.$route.query.buyNums,
          orgId: this.orgId
        }
        saveMany(data).then(res => {
          if (res.data.code !== 0) {
            this.$message.warning(res.data.msg)
            return
          } else {
            this.activityCode = res.data.activityList[0].orderCode
            this.supplierCode = res.data.agentCode
            if (this.activityCode) {
              this.submitOrder()
            } else {
              this.$message.warning(res.data.msg)
            }
          }
        })

      } else {
        this.submitOrder()
      }
    },
    // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      let standardValArr = [] // 每个组实际要求数量
      let currValArr = [] // 每个组购买总数
      let multipleArr = [] // 每个组购买总数与实际要求数量成比例
      // proItem.dataArr.forEach((aaitem) => {
      if (this.GoodsList[0].discountTypeName == '组合购') {
        proItem.forEach(val => {
          val.numOrder = 0
          val.data.forEach(item => {

            val.numOrder += Number(item.buyQtyCherk)

          })
          standardValArr.push(val.num)
          currValArr.push(val.numOrder)
          multipleArr.push(val.numOrder % val.num)
        })
      } else {
        proItem.forEach(val => {
          val.numOrder = 0
          val.numOrder += Number(val.buyQtyCherk)
          standardValArr.push(val.packageNum)
          currValArr.push(val.numOrder)
          multipleArr.push(val.numOrder % val.packageNum)
        })
      }
      // })
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false
        }
      }
      let multiple = currValArr[0] / standardValArr[0] //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false
        }
      }
      return true
    },
    // 获取配置的code
    getConfigCode() {
      // 1发送到只DMS，2 发送到CIS, 3 全部发送 ，为空时发送到dms
      let configDta = {
        key: 'QD_ORDER_SEND_CIS'
      }
      // 定义一个值 用做下面判
      sysconfig(configDta).then(res => {
        this.configCode = res.data.data
      })
    },
    // 直送用户/送装一体地区校验
    async deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
          orgId: el.fwOrgId,
          matklId: el.matklId
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "townCode":  this.proObj.zCode,
        "cityCode":   this.proObj.cityCode ,
        "provinceCode":  this.proObj.proviceCode ,
        "districtCode":this.proObj.townCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans,
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      return ctsApi(data)
    },
    // dms 订单提交
    async submitOrder() {
      let packageNum = 0
      if (this.GoodsList[0].discountTypeName == '组合购') {
        let datanum = 0
        let packNUM = this.dataArr[0].data[0].packageNum
        this.dataArr[0].data.forEach(dataItem => {
          if (dataItem.numcherk) {
            datanum += dataItem.buyQty
          }
        })

        packageNum = datanum / packNUM
        let productGroupRemark = [], packageNumArr = [];
        this.dataArr.forEach(el => {
          productGroupRemark.push(el.name)
          packageNumArr.push(el.num)
        })
        let isTrue = this.checkCombinationPurchase(this.dataArr)
        if( this.quantityControl == 1){
          let isMeetProportion = false
          isMeetProportion = this.dataArr.every(el => el.numOrder >= el.num)
          if(!isMeetProportion ) {
            this.$message.warning(`该组合活动的 ${productGroupRemark.join('和')}的数量的起订量分别为${packageNumArr.join('和')}`)
            return
          }
        }else {
          if (!isTrue) {
            this.$message.warning(`该组合活动的${productGroupRemark.join('和')}的数量需要满足${packageNumArr.join(':')}的比例`);
            // this.$message.warning('下单数量不满足组合购比例,请重新下单')
            return
          }
        }
      } else if (this.GoodsList[0].discountTypeName == '套购') {
        packageNum = this.isChooseNum
        // let datanum1 = this.GoodsList[0].buyQtyCherk
        // let packNUM1 = this.GoodsList[0].packageNum
        // packageNum = datanum1 / packNUM1
        // let isTrue = this.checkCombinationPurchase(this.GoodsList)
        //
        // if (!isTrue) {
        //   this.$message.warning('下单数量不满足套购比例,请重新下单')
        //   return
        // }
      } else {
        packageNum = 0
      }

      let productInfo = []
      let buyNum = []
      let productIds = []
      let ids = []
      let saleItem = []
      this.GoodsList.forEach(item => {
        buyNum.push(item.buyQtyCherk)
        productIds.push(item.productInfoId)
        ids = item.orderCode
        if (item.buyQtyCherk !== 0) {
          productInfo.push({
            invStatusId:'',
            invStatusType:'',
            orgCode:item.fwOrgId,
            salePrice:Number(item.salePrice),
            colour: item.color,
            amount: item.buyQtyCherk * item.billPrice,
            productCode: item.productInfoId,
            price: item.billPrice,
            model: item.productInfoZzprdmodel,
            orderedQty: item.buyQtyCherk,
            priceId: item.priceId,
            productName: item.productName,
            packageNum: packageNum, // 套购数量
            packageRateNum: item.packageNum ? item.packageNum : '',// 套够比例数
            productGroup: item.productGroup ? item.productGroup : '',
            productGroupRemark: item.productGroupRemark ? item.productGroupRemark : '',
            // packageCode:item.packageCode,
          })
        }

      })
      // 当前日期    
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth() + 1; //得到月份
      var date = now.getDate(); //得到日期
      // var day = now.getDay(); //得到周几
      var hour = now.getHours(); //得到小时
      var minu = now.getMinutes(); //得到分钟
      var sec = now.getSeconds(); //得到秒
      var time = ''
      time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;

      this.isLoading = true;
      let purchaseOrder = {
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
        //  详细地址
        // address: this.userDetailAddress,
        address:(this.trans=='04'|| this.trans=='08') ?this.userDetailAddress: this.receivehis,
        contactInfo: this.phoneCon,
        // billtoId: this.invoiceId,
        provinceId: this.proObj.proviceCode,
        cityId:  this.proObj.cityCode ,
        townId: this.proObj.zCode ,
        countryId:this.proObj.townCode  ,
        message: this.BZvalue,
        addressId: this.officeCode,
        // addressId: this.officeCode,
        totalAmount: this.goodsPrice,
        totalOrderedQty: this.goodsNum,
        warehouseId: this.receiveID,
        deliveryMode: this.trans,
        orgCode: this.orgId,
        contact: this.contacts,
        // 商品信息
        purchaseOrderItem: productInfo,
        // 当前日期
        documentDate: time,
        supplierCode: this.supplierCode,
        userId: this.userid,
        cisAddressId: this.officeCode,
        edt: this.startTime&&this.haveDeliveryFee?this.startTime.replace(/\//g,"-"):'',
        dataSource: 'xtw_pc',
        discountType: this.GoodsList[0].discountTypeId,
        discountTypeName: this.GoodsList[0].discountTypeName,
        activityNum: '',
        activityName: this.GoodsList[0].activityName,
        quantityControl: this.GoodsList[0].quantityControl?this.GoodsList[0].quantityControl:'',
        orderType: '',
        userActivityCode: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
        packageMainNum: this.$route.query.mainOrderCode,
        packageCode: this.GoodsList[0].packageCode ? this.GoodsList[0].packageCode : '',
        storeCode:  this.salesShopInfoId, // 销量所属门店
        sourceId:  this.SorceIds, // 用户来源
        customerLabels: this.trans != '04'&&this.trans != '08'?'':this.TipsIds.join(','), // 用户标签
        gender: this.trans != '04'&&this.trans != '08' ? '' : this.sexId, // 性别
        brightFlag:this.isBright
      }
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        purchaseOrder: purchaseOrder
      }
      // 如果等于1,3,'' 调用下面方法
      if(this.configCode !== '2') {
        if(this.trans == '04' || this.trans == '08'){
          orderSubmit(data).then(res => {
            var obj = JSON.parse(res.data);
            if (obj.code == '1') {
              this.isLoading = false;
              this.$message.warning(obj.msg);
              let data = {
                userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
                transFlag: 'ZF'
              }
              this.changenum(data)
            } else if (obj.code == '0') {
              let data = {
                dmsOrderCode: obj.msg,
                userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
                qtys: buyNum.join(','),
                productIds: productIds.join(','),
              }
              this.changenum(data)
              if (this.$route.query.custId || this.$route.query.type == 'rg') {
                if(obj.deliveryOrderNum) {
                  this.$router.push({
                    path: "/serviceFeeModule/pay",
                    query: {
                      id:obj.deliveryOrderNum,
                      batch:0,
                      b2bOrderCode: obj.msg,
                      orgName: '订单号',
                      channel: 1
                    }
                  });
                  return
                }
                this.$router.push({
                  path: '/confirm/confirmsuccess',
                  query: {
                    b2bOrderCode: obj.msg,
                    orgName: '订单号',
                    channel: 1
                  }
                })
              } else {
                if(obj.deliveryOrderNum) {
                  this.$router.push({
                    path: "/serviceFeeModule/pay",
                    query: {
                      id:obj.deliveryOrderNum,
                      batch:0,
                      b2bOrderCode: obj.msg,
                      orgName: '订单号',
                      channel: 1
                    }
                  });
                  return
                }
                this.$router.push({
                  path: '/submitEndActivity',
                  query: {
                    code: obj.msg,
                    fwOrgName: this.suppilers,
                    channel: 1
                    // b2bOrderCode: obj.msg,
                    // orgName: '订单号',
                    // channel: 1
                  }
                })
              }
            }
          })
        } else {
          orderSubmit2(data).then(res => {
            var obj = JSON.parse(res.data);
            if (obj.code == '1') {
              this.isLoading = false;
              this.$message.warning(obj.msg);
              let data = {
                userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
                transFlag: 'ZF'
              }
              this.changenum(data)
            } else if (obj.code == '0') {
              let data = {
                dmsOrderCode: obj.msg,
                userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
                qtys: buyNum.join(','),
                productIds: productIds.join(','),
              }
              this.changenum(data)
              if (this.$route.query.custId || this.$route.query.type == 'rg') {
                if(obj.deliveryOrderNum) {
                  this.$router.push({
                    path: "/serviceFeeModule/pay",
                    query: {
                      id:obj.deliveryOrderNum,
                      batch:0,
                      b2bOrderCode: obj.msg,
                      orgName: '订单号',
                      channel: 1
                    }
                  });
                  return
                }
                this.$router.push({
                  path: '/confirm/confirmsuccess',
                  query: {
                    b2bOrderCode: obj.msg,
                    orgName: '订单号',
                    channel: 1
                  }
                })
              } else {
                if(obj.deliveryOrderNum) {
                  this.$router.push({
                    path: "/serviceFeeModule/pay",
                    query: {
                      id:obj.deliveryOrderNum,
                      batch:0,
                      b2bOrderCode: obj.msg,
                      orgName: '订单号',
                      channel: 1
                    }
                  });
                  return
                }
                this.$router.push({
                  path: '/submitEndActivity',
                  query: {
                    code: obj.msg,
                    fwOrgName: this.suppilers,
                    channel: 1
                    // b2bOrderCode: obj.msg,
                    // orgName: '订单号',
                    // channel: 1
                  }
                })
              }
            }
          })
        }

         // 但是如果等于3 就调用此方法
         // 0714 不调了 光伟说后台相关代码已删除
        //  if(this.configCode == '3') {
        //    submitCis(data).then(res => {
        //    }).catch(error => {
        //      console.log(error);
        //    })
        //  }
      }
      // 发送到CIS代码 调sysconfig 接口如果只返回2 就只调用这个方法 不调用 上边
      // 0714 不调了 光伟说后台相关代码已删除
      // if(this.configCode == '2' ) {
      //   submitCis(data).then(res => {
      //     var obj = res.data;
      //     if (obj.code == '1') {
      //       this.isLoading = false;
      //       this.$message.warning(obj.msg);
      //       let data = {
      //         userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
      //         transFlag: 'ZF'
      //       }
      //       this.changenum(data)
      //     } else if (obj.code == '0') {
      //       let data = {
      //         dmsOrderCode: obj.msg,
      //         userActivityCodes: this.$route.query.custId ? this.activityCode : this.GoodsList[0].orderCode,
      //         qtys: buyNum.join(','),
      //         productIds: productIds.join(','),
      //       }

      //       this.changenum(data)
      //       if (this.$route.query.custId || this.$route.query.type == 'rg') {
      //         if(obj.deliveryOrderNum) {
      //           this.$router.push({
      //             path: "/serviceFeeModule/pay",
      //             query: {
      //               id:obj.deliveryOrderNum,
      //               batch:0,
      //               b2bOrderCode: obj.msg,
      //               orgName: '订单号',
      //               channel: 1
      //             }
      //           });
      //           return
      //         }
      //         this.$router.push({
      //           path: '/confirm/confirmsuccess',
      //           query: {
      //             b2bOrderCode: obj.msg,
      //             orgName: '订单号',
      //             channel: 1
      //           }
      //         })
      //       } else {
      //         this.$router.push({
      //           path: '/submitEndActivity',
      //           query: {
      //             code: obj.msg,
      //             fwOrgName: this.suppilers,
      //             channel: 1
      //             // b2bOrderCode: obj.msg,
      //             // orgName: '订单号',
      //             // channel: 1
      //           }
      //         })
      //       }
      //     }
      //   }).catch(error => {
      //     console.log(error);
      //   })
      // }




    },
    changenum(data) {
      changeTransFlag(data).then(res => {
      }).catch(error => {
        console.log(error);
      })
    },
    // // 删除接口
    // deletGoods() {
    //   let params = {
    //     cartIds: this.RouterParams.carts
    //   }
    //   deletGoods(params).then(res => {
    //   })
    // },
    // 时间更改
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.startTime && this.haveDeliveryFee){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.startTime){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    handleTimeChange(value,dateString) {
      this.startTime = dateString;
      this.endOpen = false
    },
    // 控制可选时间范围
    // disabledDate(current) {
    //   // current < moment(new Date(2020 - 11 - 11)) ||
    //   return current < moment().startOf('days') || current > new Date(this.maxEndDate)
    // },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回购物车
    goCar() {
      // this.$router.push("/marketActivityResult");
      if(this.$route.query.pageType='activity'){
        window.close()
      }
      this.$router.go(-1)
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      // 筛选出来当前选择的数据
      let obj = this.shippingAddress.find((i)=>{//listData就是上面的数据源
        return i.id === e.key;//筛选出匹配数据
      });
      // 如果选择的地址状态为D 说明地址失效 弹出提示
      if(obj.regionStatus == 'D') {
        this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
        this.receivehis = ''
        this.distributionAdd.DISCODE = ''
        this.officeCode = ''
        return
      }
      this.receivehis = value;
      this.distributionAdd.DISCODE = e.key;

      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.showAnotheer()
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      }).then(res => {
      }).catch(err => {
        console.log(err)
      })
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList()
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {
        this.choseDistribution = res.data
        this.distributionAdd.DISCODE = res.data[0].id
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;

    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value
      this.distrAddress = value;
      getPhone({
        sendToId: e.key
      }).then(res => {
        this.contacts = res.data.contact;
        this.phoneCon = res.data.phone;
        this.changePhoneNum()
      })
    },
    // 分销商地址切换获取联系人
    // 获取手机号
    getPeople(sendToId) {
      getPhone({
        sendToId
      }).then(res => {

        this.contacts = res.data.contact == "无" ? '' : res.data.contact;
        this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        this.changePhoneNum()
      })
    },
    addItem() {
      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    handleChangeInt(e) {
    },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType
      }
      quotaDeatail(data).then(res => {
        this.data = res.data.dataList;
        this.pager.count = res.data.dataList.length;
      })
    },

    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressDftVal = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.proObj.proviceCode = townCode.slice(0,2).padEnd(12, 0);
      this.proObj.cityCode = townCode.slice(0,4).padEnd(12, 0);
      this.proObj.townCode = townCode.slice(0,6).padEnd(12, 0);
      this.proObj.zCode = townCode.padEnd(12, 0);
      // 详细地址
      this.userDetailAddress = res.address;
      // 姓名
      this.contacts = res.userName;
      // 电话
      this.phoneCon = res.mobile;
    }
  }
}