import http from "@/utils/request";

import dmshttp from "@/utils/dmsrequest";




// 在线充值
export function saveMoney() {
    return http({
        url: '/cart/selfOrderPay.nd',
        method: 'get'
    })
}
// 充值记录

export function saveHisetory() {
    return http({
        url: '/cart/selfOrderQuery.nd',
        method: 'get'
    })
}
// 分销商渠道采购

// 查询商家地址

export function getAddress(data) {
    return http({
        url: '/address/changeDistributionAddressByCode.nd',
        method: 'post',
        data
    })
}
// 下单完成后删除购物车商品
export function deletGoods(params) {
    return http({
        url: '/cart/deleteProduct.nd',
        method: 'get',
        params
    })
}
// 查询 供应商 结算单位等
export function getSupplierS(data) {
    return dmshttp({
        url: 'findBaseData',
        data
    })
}
// 配送方式
export function getDeliveryMode(data) {
    return dmshttp({
        url: 'getDeliveryMode',
        data
    })
}

// 查询商品信息 
export function getCustomer(data) {
    return http({
        url: '/marketActivity/actToOrderInit.nd',
        method: 'post',
        data
    })
}
// dms 订单提交
export function orderSubmit(data) {
    return dmshttp({
        url: 'submitBatchPurchaseOrder2c',
        data
    })
}


//  //分享商渠道采购



// 渠道采购 配送方式等查询
export function specialZone(params) {
    return http({
        url: '/cart/settlement.nd',
        method: 'get',
        params
    })
}


// 特惠订单提交
export function specialSubmit(data) {
    return http({
        url: '/preferential/saveProductForm.nd',
        method: 'post',
        data
    })
}

//时效信息
export function orderWeek(data) {
    return http({
        method: "post",
        url: "/cart/orderWeek.nd",
        data
    });
}
// 购物车查询  
export function orderCar(params) {
    return http({
        url: '/cart/settlement.nd',
        method: 'get',
        params
    })
}
// 统仓地址联系人address/changeCustomerAddress.nd
export function orderAddress(data) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
}

// 分销商 分销地址

export function showAnother(params) {
    return http({
        url: '/cart/showAnotherOrderList.nd',
        method: 'get',
        params
    })
}

// 收货地址切换 带出联系人
export function addessChange(params) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'get',
        params
    })
}

// 获取城市接口
export function cityChange(data) {
    return http({
        url: '/customer/getCity.nd',
        method: 'post',
        data
    })
}

// 获取城镇区
export function townChange(data) {
    return http({
        url: '/customer/getDistrict.nd',
        method: 'post',
        data
    })
}

// 获取城镇区下一级
export function getQu(data) {
    return http({
        url: '/customer/getTown.nd',
        method: 'post',
        data
    })
}

// 分销切换 请求分销商地址
export function getDISAdd(data) {
    return http({
        url: '/address/changeDistributionAddress.nd',
        method: 'post',
        data
    })
}
// 地址切换 获取联系人
export function getPhone(data) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
}

// 要求到货周 
export function getWeek(data) {
    return http({
        url: '/cart/orderWeek.nd',
        method: 'post',
        data
    })
}

// 结算商品列表  价格信息等 
export function goodsList(data) {
    return http({
        url: '/product/getPrices.nd',
        method: 'post',
        data
    })
}

// 分销价格
export function fenxiaoPrice(data) {
    return http({
        url: '/product/fxPrice.nd',
        method: 'post',
        data
    })
}

// 获取库存数 
export function getNumber(data) {
    return http({
        url: '/product/getStocks.nd',
        method: 'post',
        data
    })
}

// 购物车订单提交
export function cartSubmit(data) {
    return http({
        url: '/cart/saveOrder.nd',
        method: 'post',
        data
    })
}
// 查看预占用额度明细
export function quotaDeatail(params) {
    return http({
        url: 'balance/queryWaitBalanceInfoList.nd',
        method: 'get',
        params
    })
}

export function changeTransFlag(data) {
    return http({
        url: '/marketActivity/changeTransFlag.nd',
        method: 'post',
        data
    })
}
export function settlementZone(data) {
    return http({
        url: '/marketActivity/settlement.nd',
        method: 'post',
        data
    })
}
export function saveMany(data) {
    return http({
        url: '/marketActivity/saveMany.nd',
        method: 'post',
        data
    })
}

// dms迁移cis接口
export function submitCis(data) {
    return http({
        url: '/dmsPurchaseOrder/submit.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}
// /comm/sysconfig.nd 是否开启渠道订单转移到cis
export function sysconfig(data) {
    return http({
        url: '/comm/sysconfig.nd',
        method: 'post',
        data
    })
}